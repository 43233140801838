<template>
  <div class="p-4">
      <about-page></about-page>
  </div>
</template>
<script>

import AboutPage from "@/components/AboutPage.vue";
export default {
  components: {
    AboutPage,
  }
}
</script>